.export_banner {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.63) 30%, rgb(0, 0, 0, 0.63)), url('https://www.viralspices.com/wp-content/uploads/2023/09/Guide-to-Choosing-Reliable-Spice-Exporters-in-India-624x312.jpg');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.custom-button {
  display: inline-block;
  padding: 10px 20px;
  background-color:#343f75; /* Adjust color to fit UI */
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  text-decoration: none; /* Removes underline from anchor tags */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effects */
}

.custom-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slight zoom-in on hover */
}

.custom-button:active {
  background-color: #003f8a; /* Even darker blue on click */
  transform: scale(0.98); /* Slight shrink on click */
}
/* Container for the gallery */
.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjusted min-width for mobile */
  gap: 20px;
  padding: 10px;
  justify-content: center;
}

/* Ensure larger cards on wider screens */
@media (min-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}

/* Individual card styling */
.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Uniform media (image or video) styling */
.media {
  width: 100%;
  height: 300px;        /* Fixed height */
  object-fit: cover;    /* Maintain aspect ratio, center cropped */
  border-radius: 8px;
  margin-bottom: 10px;
}

/* Title styling */
.title {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #333;
}

/* Description styling */
.description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}
