.hero_banner {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.63) 30%, rgb(0, 0, 0, 0.63)), url('../image/slide_2.jpg');
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

// .staff_card:nth-child(2) {
//   margin-top: -20px !important;
// }

.staff_card:hover .absolute {
  bottom: 0 !important;
}

.hero_social_btn {
  background-color: rgba(255, 255, 255, 0.047);
  backdrop-filter: blur(5px);
}

.percentage_value {
  // font-family: 'Bebas Neue', sans-serif !important;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
}

.features_slider {
  width: 100vw;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.63) 30%, rgb(0, 0, 0, 0.63)), url('../image/featues_slider.jpg');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.service_steps {
  width: 100vw;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.63) 30%, rgb(0, 0, 0, 0.63)), url('../image/service_steps.jpg');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.swiper-slide {
  width: 600px !important;
  overflow: hidden;
  transition: .3s ease-in !important;
}

.swiper-slide-active {
  transform: scale(1.1);
}

.project_image_wrap:hover img {
  transform: scale(1.5);
}

.image_overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.63) 30%, rgb(0, 0, 0, 0.63));
}

.highlight_wrap img {
  transition: .3s ease-in;
}

.highlight_wrap:hover img {
  transform: scale(1.1);
}